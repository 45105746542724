<template>
  <div id="Marketing">
    <div id="ver__mas">Ver más</div>
    <Header :white="true" />
    <div class="mkt-body gradientBK">
      <div class="header-title">
        <div class="cont">
          <p class="titulo">Development</p>
          <p class="descrp">
            Nada es imposible cuando tu sueño está en buenas manos.
          </p>
          <a href="#scrolled">
            <ScrollBehind />
          </a>
        </div>
        <div class="items-cont">
          <img src="../assets/services/s1/img1.png" alt="img" class="im1" />
          <img src="../assets/services/s1/img2.png" alt="img" class="im2" />
          <img src="../assets/services/s1/img3.png" alt="img" class="im3" />
          <img src="../assets/services/s1/img4.png" alt="img" class="im4" />
          <img src="../assets/services/s1/img5.png" alt="img" class="im5" />
          <img src="../assets/services/s1/img3.png" alt="img" class="im6" />
          <img src="../assets/services/s1/img2.png" alt="img" class="im7" />
          <img src="../assets/services/s1/img2.png" alt="img" class="im8" />
        </div>
      </div>
      <div class="sec-1">
        <div class="sec-1-cont">
          <div class="s1-col" data-aos="fade-zoom-in" data-aos-easing="ease-in-back" data-aos-duration="500">
            <div class="head-col" id="scrolled">
              <p class="mbottom">
                La transformación digital es inminente, ¿para qué seguir
                esperando? Con expertos en desarrollo web, hemos formado un gran
                equipo con las habilidades necesarias para hacer realidad
                cualquier tipo de plataforma digital y unirte a la nueva era.
              </p>
              <p>
                Todos nuestros proyectos son diseñados desde una perspectiva UI
                (Interfaz de Usuario) y UX (Experiencia de Usuario) para
                hacerlos perfectamente funcionales en los objetivos de tu marca.
              </p>
            </div>

            <div class="three-items">
              <div class="col-img-1 pc">
                <img src="../assets/dev1.jpg" alt="img" class="devimg-1 ">
                <img src="../assets/dev2.jpg" alt="img" class="devimg-2 ">
              </div>
              <div class="point-cont">
                <a href="/tienda-en-linea" class="only-pc">
                  <div class="points objetivo" @mousemove="moving">
                    <p class="titulo">Tienda en línea</p>
                    <p class="texto">
                      Prepárate para vender en línea tus productos y servicios.
                      Nuestras tiendas en línea están habilitadas para recibir pagos y
                      procesar envíos a todas partes del mundo.
                    </p>
                  </div>
                </a>
                <div class="points only-mov">
                  <p class="titulo">Tienda en línea</p>
                  <p class="texto">
                    Prepárate para vender en línea tus productos y servicios.
                    Nuestras tiendas en línea están habilitadas para recibir pagos y
                    procesar envíos a todas partes del mundo.
                  </p>
                  <a href="/tienda-en-linea" class="ver__mov">Ver más</a>
                </div>
                <div class="points only-mov">
                  <p class="titulo">Sitios web</p>
                  <p class="texto">
                    No hay mejor presentación para darte a conocer en el mundo
                    digital. Un sitio web te da notoriedad, confianza y un medio
                    para que tus clientes te encuentren con facilidad.
                  </p>
                </div>
                <a href="/desarrollo-web " class="only-pc">
                  <div class="points objetivo" @mousemove="moving">
                    <p class="titulo">Sitios web</p>
                    <p class="texto">
                      No hay mejor presentación para darte a conocer en el mundo
                      digital. Un sitio web te da notoriedad, confianza y un medio
                      para que tus clientes te encuentren con facilidad.
                    </p>
                  </div>
                </a>
                <div class="points">
                  <p class="titulo">Páginas de destino</p>
                  <p class="texto">
                    Para mejorar los resultados y el control de tus campañas de Meta
                    y Google Ads, necesitas una página de aterrizaje. Se trata de un
                    desarrollo web sencillo, dirigido a generar una acción inmediata
                    de tu cliente objetivo. Por ejemplo: Visitar tu página, realizar
                    una suscripción o una compra en línea.
                  </p>
                </div>
                <div class="points">
                  <p class="titulo">Software para empresas  </p>
                  <p class="texto">
                    Minimiza tiempos y recursos con un software a la medida de tu
                    empresa. Desde un control de inventario hasta sistemas que midan
                    la productividad de tus empleados.
                  </p>
                </div>
                <div class="points">
                  <p class="titulo">Aplicaciones móviles</p>
                  <p class="texto">
                    Reservar una consulta médica, comprar boletos de avión, pagar
                    servicios o realizar compras en tu celular, todo esto posible a
                    través de una aplicación móvil.
                  </p>
                </div>
                <div class="points">
                  <p class="titulo">Sistema de citas</p>
                  <p class="texto">
                    ¡Empieza a poner orden! Con un sistema de citas puedes llevar un
                    mejor control de tu agenda y facilitas el camino de tu cliente a
                    tus servicios.
                  </p>
                </div>
              </div>
              <div class="col-img-2 pc">
                <img src="../assets/dev3.jpg" alt="img" class="devimg-3">
                <img src="../assets/dev4.jpg" alt="img" class="devimg-4">
                <img src="../assets/dev5.jpg" alt="img" class="devimg-5">
              </div>
              <div class="col-img-1 mo" data-aos="fade-zoom-in" data-aos-easing="ease-in-back" data-aos-duration="500">
                <img src="../assets/dev1.jpg" alt="img" class="devimg-1 ">
                <img src="../assets/dev2.jpg" alt="img" class="devimg-2 ">
              </div>
              <div class="col-img-2 mo" data-aos="fade-zoom-in" data-aos-easing="ease-in-back" data-aos-duration="500">
                <img src="../assets/dev3.jpg" alt="img" class="devimg-3">
                <img src="../assets/dev4.jpg" alt="img" class="devimg-4">
                <!--<img src="../assets/j5.png" alt="img" class="devimg-5" 
                v-rellax="{speed: -3,}">-->
              </div>
            </div>
          </div>

          <div class="ddm-sec" data-aos="fade-zoom-in" data-aos-easing="ease-in-back" data-aos-duration="500">
            <p class="pc">¿No encuentras lo que necesitas?</p>
            <p class="pc"> No dudes en <a href="/home/contact-us">hablarnos.</a></p>
            <p class="mo">¿No encuentras lo <br class="mo"> que necesitas? No dudes <br class="mo"> en <a
                href="/home/contact-us">hablarnos.</a></p>
          </div>
        </div>
      </div>
    </div>
    <Footer />
  </div>
</template>
<script>
import Header from "../components/on-site/Header";
import Footer from "../components/on-site/Footer";
import ScrollBehind from "../components/on-site/ScrollBehind";
export default {
  components: {
    Header,
    Footer,
    ScrollBehind,
  },
  methods: {
    moving(event) {
      const x = event.clientX;
      const y = event.clientY;
      const verMas = document.getElementById("ver__mas");
      verMas.style.left = x + 'px';
      verMas.style.top = y + 'px';
    },

    changeBackgroundPartners(event) {
      /***logoLegra */
      let media768 = window.matchMedia("(max-width: 768px)");
      let min768 = window.matchMedia("(min-width: 768px)");
      let divcont = document.getElementsByClassName("gradientBK")[0];
      let divcont1 = document.getElementsByClassName("logoLegra")[0];
      let number = event.target.documentElement.scrollTop;
      let offset = number * 0.09615384615384615;
      if (media768.matches) {
        if (offset <= 45) {
          divcont.style.backgroundColor = "#3702FB";
          divcont1.style.filter = "brigthness(2)";
        }
        if (offset >= 45) {
          divcont.style.backgroundColor = "#FFFFFF";
          divcont1.style.filter = "brigthness(1)";
        }
      } else if (min768.matches) {
        /**#e4e4f9 */
        if (offset <= 57) {
          divcont.style.backgroundColor = "#3702FB";
          divcont1.style.filter = "brigthness(2)";
        }
        if (offset >= 57) {
          divcont.style.backgroundColor = "#FFFFFF";
          divcont1.style.filter = "brigthness(1)";
        }
      }
    },
  },
  mounted() {
    window.addEventListener("scroll", this.changeBackgroundPartners);
  },
  watch: {
    $route: {
      immediate: true,
      handler(to, from) {
        document.title = to.meta.title || 'Development - Legrafica';
      }
    },
  }
};
</script>
<style scoped>
.im1 {
  position: absolute;
  top: 6.510vw;
  left: 30.833vw;
  width: 41.042vw;
  height: auto;
}

.im2 {
  position: absolute;
  top: 12.188vw;
  left: 66.354vw;
  width: 6.667vw;
  height: auto;
}

.im3 {
  position: absolute;
  top: 19.219vw;
  left: 16.927vw;
  width: 5.208vw;
  height: auto;
}

.im4 {
  position: absolute;
  top: 11.563vw;
  left: 35.156vw;
  width: 7.656vw;
  height: auto;
}

.im5 {
  position: absolute;
  top: 11.563vw;
  left: 75.781vw;
  width: 9.688vw;
  height: auto;
}

.im6 {
  position: absolute;
  top: 31.563vw;
  left: 75.781vw;
  width: 2.552vw;
  height: auto;
}

.im7 {
  position: absolute;
  top: 28.698vw;
  left: 24.740vw;
  width: 3.906vw;
  height: auto;
}

.im8 {
  position: absolute;
  top: 27.448vw;
  left: 82.188vw;
  width: 1.563vw;
  height: auto;

}

.mkt-body {
  background-color: #3702fb;
}

.header-title .cont {
  /*padding: 20.26vw 0;*/
  /*margin: 0 auto;*/
  /*width: 63.958vw;*/
  position: absolute;
  padding: 20.26vw 0 20.26vw 26.823vw;
  z-index: 40;
  width: fit-content;
  margin: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.header-title .titulo {
  font-family: "Gramatika-Medium";
  font-weight: 400;
  font-size: 7.813vw;
  line-height: 121.3%;
  /*color: #ffb6ea;*/
  color: white;
  text-align: center;
  margin: 0 0 3.906vw;
}

.header-title .descrp {
  font-family: "Gramatika-Medium";
  font-weight: 400;
  font-size: 1.146vw;
  line-height: 147%;
  text-align: center;
  color: #ffffff;
  margin: 0;
}

.s1-col {
  width: 100%;
}

.head-col {
  width: 51.667vw;
  margin: 0 auto 12.552vw;
}

.head-col .mbottom {
  margin: 0 0 2.083vw;
}

.head-col p {
  font-family: "IBM Plex Mono";
  font-style: normal;
  font-weight: 400;
  font-size: 0.833vw;
  line-height: 179.8%;
  color: #000000;
  margin: 0;
  text-align: center;
}

.three-items {
  display: flex;
  /*justify-content: space-between;*/
}

.point-cont {
  display: flex;
  flex-direction: column;
  gap: 5vw;
  margin-top: 8.490vw;
  margin-left: 5.104vw;
}

.col-img-2 {
  margin-left: 9.01vw;
}

.sec-1 {
  position: relative;
  padding-top: 52.292vw;
}

.sec-1-cont {
  /* width: 82.448vw;*/
  width: 100%;
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 18.49vw;
  flex-direction: column;
}

.ddm-sec {
  margin-top: 10.594vw;
}

.points {
  width: 22.563vw;
  padding-left: calc(5.604vw / 2);
  padding-right: calc(2.604vw / 2);
  padding-bottom: calc(6.771vw / 8);
  padding-top: calc(6.771vw / 8);
  background-image: url("../assets/linea-1.svg");
  background-size: 1.563vw;
  background-repeat: no-repeat;
  position: relative;
  left: -2.604vw;
}

.points .titulo {
  font-family: "IBM Plex Mono";
  font-style: normal;
  font-weight: 700;
  font-size: 0.833vw;
  line-height: 179.8%;
  color: #ef67c2;
  margin: 0;
}

.points .texto {
  font-family: "IBM Plex Mono";
  font-style: normal;
  font-weight: 400;
  font-size: 0.833vw;
  line-height: 179.8%;
  color: #000000;
  margin: 0;
}

.s1-col-2 {
  width: 38.542vw;
}

.row-1 {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}

.s1-col-2 .item-1 {
  width: 14.01vw;
  height: auto;
}

.s1-col-2 .item-2 {
  width: 21.302vw;
  height: auto;
  position: relative;
  top: 1.302vw;
}

.row-2 {
  display: flex;
  justify-content: center;
  margin-top: 5.521vw;
}

.s1-col-2 .item-3 {
  width: 28.385vw;
  height: auto;
}

.nona {
  display: none;
}

.sec-1 {
  display: flex;
  justify-content: space-between;
}

.col-img-1,
.col-img-2 {
  display: flex;
  flex-direction: column;
  width: auto;
}

.col-img-1.pc,
.col-img-2.pc {
  display: flex;
}

.col-img-1.pc {
  padding-top: 15vw;
}

.col-img-1.mo,
.col-img-2.mo {
  display: none;
}

.devimg-1 {
  width: 21.302vw;
  height: auto;
}

.devimg-2 {
  width: 24.51vw;
  height: auto;
  margin-top: 11.667vw;
  margin-left: 7.656vw;
}

.devimg-3 {
  width: 31.656vw;
  height: auto;
  margin-left: -6.604vw;
  margin-bottom: 8.583vw;
}

.devimg-4 {
  width: 25.5vw;
  height: auto;
  margin-left: -2.833vw;
  margin-bottom: 12.594vw;
}

.devimg-5 {
  width: 25.052vw;
  height: auto;
}

.objetivo:hover {
  cursor: none;
}

#Marketing:has(.objetivo:hover) #ver__mas {
  opacity: 1;
  z-index: 2;
}

#ver__mas {
  font-family: "Gramatika-Bold";
  font-size: 0.8333333333333334VW;
  line-height: 152.3%;
  text-align: center;
  text-decoration: none;
  color: #FFF;

  width: 6.614583333333333VW;
  height: 6.614583333333333VW;
  background-color: #3702FB;
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100%;

  top: 0;
  left: 0;
  cursor: none;
  opacity: 0;
  pointer-events: none;
  z-index: -1;
  transform: translate(-50%, -50%);
}

.only-mov {
  display: none !important;
}


@media (max-width: 768px) {
  #ver__mas {
    display: none !important;
  }

  .ver__mov {
    width: 14.492753623188406VW;
    height: 14.492753623188406VW;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: transparent;
    border-radius: 100%;
    border: 0.24154589371980675VW solid #987EF3;
    color: #987EF3;
    font-family: "Gramatika-Bold";
    font-size: 2.4154589371980677VW;
    line-height: 106.8%;
    margin: 7.246376811594203VW 0 0 auto;
  }

  .only-pc {
    display: none !important;
  }

  .only-mov {
    display: block !important;
  }

  .devimg-1 {
    width: 33.575vw;
    height: auto;
    margin-top: auto;
  }

  .devimg-2 {
    width: 46.377vw;
    height: auto;
    margin-top: 6.908vw;
    margin-left: 1.833vw;
  }

  .devimg-3 {
    width: 40.609vw;
    height: auto;
    margin-top: 2.908vw;
    margin-left: 0vw;
    margin-bottom: 8.193vw;
  }

  .devimg-4 {
    width: 38.406vw;
    height: auto;
    margin-top: 2.908vw;
    margin-left: 1.833vw;
    margin-bottom: 8.594vw;
  }

  .devimg-5 {
    width: 25.052vw;
    height: auto;
  }

  .sec-1 {
    position: relative;
    padding-top: 224.396vw;
  }

  .im1 {
    top: 27.295vw;
    left: 0;
    width: 100vw;
  }

  .im2 {
    position: absolute;
    top: 23.913vw;
    left: 85.024vw;
    width: 4.106vw;
    height: auto;
  }

  .im3 {
    position: absolute;
    top: 27.295vw;
    left: 17.633vw;
    width: 3.14vw;
    height: auto;
  }

  .im4 {
    position: absolute;
    top: 116.425vw;
    left: 63.527vw;
    width: 24.879vw;
    height: auto;
  }

  .im5 {
    top: 27.295vw;
    left: -11.594vw;
    width: 44.928vw;
  }

  .im6 {
    position: absolute;
    top: 58.213vw;
    left: 89.614vw;
    width: 5.556vw;
    height: auto;
  }

  .im7 {
    position: absolute;
    top: 62.802vw;
    left: 2.657vw;
    width: 7.005vw;
    height: auto;
  }

  .im8 {
    display: none;
  }

  .nona {
    display: block;
  }

  .header-title .cont {
    padding: 57.971vw 0 57.971vw 6.425vw;
  }

  .header-title .titulo {
    font-size: 13.285vw;
  }

  .header-title .descrp {
    font-size: 3.623vw;
    width: 68.357vw;
  }

  .sec-1-cont {
    width: 100%;
    padding-bottom: 43.72vw;
    flex-direction: column;
  }

  .point-cont {
    margin-top: 6.280vw;
    margin-left: 15.459vw;
    /*position: absolute;*/
    z-index: 9;
  }

  .s1-col {
    width: 100%;
  }

  .head-col p {
    font-size: 2.899vw;
    text-align: left;
  }

  .head-col .mbottom {
    margin: 0 0 7.246vw;
  }

  .head-col {
    width: 69.324vw;
    margin-bottom: 25.845vw;
  }

  .points .titulo {
    font-size: 2.899vw;
  }

  .points .texto {
    font-size: 2.899vw;
  }

  .points {
    padding-left: 12.077vw;
    padding-bottom: 12.077vw;
    background-size: 7.246vw;
    left: 0;
    width: 53.382vw;
    margin: auto 0 0 0;
  }

  .three-items {
    width: 100%;
    /*height: 325vw;*/
    height: auto;
    justify-content: space-between;
    flex-direction: column;
  }

  .s1-col-2 {
    width: 100%;
  }

  .s1-col-2 .item-1 {
    width: 33.575vw;
  }

  .s1-col-2 .item-2 {
    width: 42.271vw;
    top: 4.302vw;
  }

  .s1-col-2 .item-3 {
    width: 68.116vw;
  }

  .row-2 {
    margin-top: 11.521vw;
  }

  .col-img-1 {
    margin-left: 9.01vw;
  }

  .col-img-1.pc,
  .col-img-2.pc {
    display: none;
  }

  .col-img-1.mo,
  .col-img-2.mo {
    display: flex;
    position: relative;
    flex-direction: row;
    align-items: flex-start
  }

  .col-img-1.mo {
    top: 0;
  }

  .col-img-2.mo {
    top: 0;
  }

  .ddm-sec {
    width: auto;
  }
}
</style>