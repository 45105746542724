<template>

  <div id="FixedItems">
    <div class="circle-part">
      <a :href="this.web">
        <div class="spin">
          <div class="font-cont">
           <p class="font">
              <span class="uno">Scroll </span>
             <!--  <span class="due">Next </span> -->
            </p>
            ▼
          </div>
          <div class="circulo">
          <div class="rotate">
          <svg height="200" width="200">
            <circle
              class="circle"
              cx="100"
              cy="100"
              r="82"
              fill="white"
              stroke="#ffffff"
              stroke-width="1"
              fill-opacity="1"
            />
          </svg></div>
          <div class="circlediv">
          <svg height="200" width="200">
            <circle
              class="circle-f"
              cx="100"
              cy="100"
              r="82"
              stroke="#ffffff"
              stroke-width="1"
              fill-opacity="0"
            />
          </svg></div></div>
        </div>
      </a>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    web: "#LastProjects",
  },
  methods: {
  },
};
</script>
<style scoped>
#FixedItems {
  position: relative;
  bottom: 0;
  left: 0;
  margin-top: 2.74vw;
  z-index: 500;
}

/**CIRCLE */
.spin {
  border: 0;
  position: relative;
 /* background-color: #b1acfc7e;*/
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
    height: 7vw;
    width: 7vw;
  transition: 0.5s;
}.circulo {
    transform: rotate(270deg);
}
.circle {
  stroke-dasharray: 1000;
  stroke-dashoffset: 1000;    
  r: 4.007813vw!important;
  stroke: rgb(255, 255, 255);
  stroke-width: 0.19765625vw;
  transition: .5s;
    fill: #ffffff00!important;
}
.circle-f {
  stroke-dasharray: 0;
  stroke-dashoffset: 0;    
  r: 4.007813vw!important;
  stroke: rgba(255, 255, 255, 0.494)!important;
  stroke-width: 0.09765625vw;
}.circlediv {
    position: absolute;
    top: 0;
}

.spin:hover .circle{
  animation: stroke 5s ease-out forwards infinite;
}

.font-cont {
    display: flex;
    justify-content: center;
    width: 2vw;
    align-items: center;
    position: absolute;
  font-family: "Gramatika-Medium";
  font-size: 0.9vw;
  line-height: 0.9vw;
  color: #ffffff;
    z-index: 9;
}
.font {
  display: flex;
  flex-direction: column;
  letter-spacing: 0;
  animation-name: spacingwords;
  animation-duration: 5s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
  animation-delay: 3s;
  opacity: 1;
  margin-right: 0.3vw;
  max-height: 3vw;
  margin: 0;
}

.spin:hover .font,
.spin.circle:hover {
  color: rgb(255, 255, 255);
}
.font .uno {
  max-height: 3vw;
  opacity: 1;
  pointer-events: none;
  transition: 0.5s;
}
.font .due {
  max-height: 0;
  opacity: 0;
  pointer-events: none;
  transition: 0.5s;
}
/*.spin:hover .font .due {
  max-height: 3vw;
  opacity: 1;
}
.spin:hover .font .uno {
  max-height: 0;
  opacity: 0;
}*/
@keyframes stroke {
 25% {
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dashoffset: 0;
  }
  75% {
    stroke-dashoffset: 1000;
  }
  100% {
    stroke-dashoffset: 1000;
  }
}

@keyframes spacingwords {
  0% {
    letter-spacing: 0;
    opacity: 1;
  margin-right: .3vw;
  }
  20% {
    letter-spacing: -0.5vw;
    opacity: 0;
  margin-right: 0;
  }
  40% {
    letter-spacing: -0.5vw;
    opacity: 0;
  margin-right: 0;
  }
  60% {
    letter-spacing: 0;
    opacity: 1;
  margin-right: .3vw;
  }
  100% {
    letter-spacing: 0;
    opacity: 1;
  margin-right: .3vw;
  }
}

/**SOCIAL MEDIA CONT */
.sm-cont {
  width: 14.74609375vh;
  display: flex;
  justify-content: space-between;
  margin-top: 5.76171875vh;
}
.sm-cont img {
  width: auto;
  height: 1.953125vh;
  opacity: 1;
  transition: .5s;
}.sm-cont img:hover
{
  opacity: .5;
}

@media (max-width: 768px) {
#FixedItems {
    margin-top: 15.74vw;
}
.circle-f {
    r: 18.0078125vw!important;
    stroke-width: 0.19765625vw;
}
.circle {
    r: 18.0078125vw!important;
    stroke-width: 0.57656vw;
}
.font-cont {
    /*
    font-size: 3.9vw;
    line-height: 3.9vw;
    */
    font-size: 5vw;
    line-height: 3.8vw;
}

@keyframes spacingwords {
  0% {
    letter-spacing: 0;
    opacity: 1;
  margin-right: .3vw;
  }
  20% {
    letter-spacing: -2.5vw;
    opacity: 0;
  margin-right: 0;
  }
  40% {
    letter-spacing: -2.5vw;
    opacity: 0;
  margin-right: 0;
  }
  60% {
    letter-spacing: 0;
    opacity: 1;
  margin-right: .3vw;
  }
  100% {
    letter-spacing: 0;
    opacity: 1;
  margin-right: .3vw;
  }
}
}
</style>