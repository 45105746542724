var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"id":"Marketing"}},[_c('div',{attrs:{"id":"ver__mas"}},[_vm._v("Ver más")]),_c('Header',{attrs:{"white":true}}),_c('div',{staticClass:"mkt-body gradientBK"},[_c('div',{staticClass:"header-title"},[_c('div',{staticClass:"cont"},[_c('p',{staticClass:"titulo"},[_vm._v("Development")]),_c('p',{staticClass:"descrp"},[_vm._v(" Nada es imposible cuando tu sueño está en buenas manos. ")]),_c('a',{attrs:{"href":"#scrolled"}},[_c('ScrollBehind')],1)]),_vm._m(0)]),_c('div',{staticClass:"sec-1"},[_c('div',{staticClass:"sec-1-cont"},[_c('div',{staticClass:"s1-col",attrs:{"data-aos":"fade-zoom-in","data-aos-easing":"ease-in-back","data-aos-duration":"500"}},[_vm._m(1),_c('div',{staticClass:"three-items"},[_vm._m(2),_c('div',{staticClass:"point-cont"},[_c('a',{staticClass:"only-pc",attrs:{"href":"/tienda-en-linea"}},[_c('div',{staticClass:"points objetivo",on:{"mousemove":_vm.moving}},[_c('p',{staticClass:"titulo"},[_vm._v("Tienda en línea")]),_c('p',{staticClass:"texto"},[_vm._v(" Prepárate para vender en línea tus productos y servicios. Nuestras tiendas en línea están habilitadas para recibir pagos y procesar envíos a todas partes del mundo. ")])])]),_vm._m(3),_vm._m(4),_c('a',{staticClass:"only-pc",attrs:{"href":"/desarrollo-web "}},[_c('div',{staticClass:"points objetivo",on:{"mousemove":_vm.moving}},[_c('p',{staticClass:"titulo"},[_vm._v("Sitios web")]),_c('p',{staticClass:"texto"},[_vm._v(" No hay mejor presentación para darte a conocer en el mundo digital. Un sitio web te da notoriedad, confianza y un medio para que tus clientes te encuentren con facilidad. ")])])]),_vm._m(5),_vm._m(6),_vm._m(7),_vm._m(8)]),_vm._m(9),_vm._m(10),_vm._m(11)])]),_vm._m(12)])])]),_c('Footer')],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"items-cont"},[_c('img',{staticClass:"im1",attrs:{"src":require("../assets/services/s1/img1.png"),"alt":"img"}}),_c('img',{staticClass:"im2",attrs:{"src":require("../assets/services/s1/img2.png"),"alt":"img"}}),_c('img',{staticClass:"im3",attrs:{"src":require("../assets/services/s1/img3.png"),"alt":"img"}}),_c('img',{staticClass:"im4",attrs:{"src":require("../assets/services/s1/img4.png"),"alt":"img"}}),_c('img',{staticClass:"im5",attrs:{"src":require("../assets/services/s1/img5.png"),"alt":"img"}}),_c('img',{staticClass:"im6",attrs:{"src":require("../assets/services/s1/img3.png"),"alt":"img"}}),_c('img',{staticClass:"im7",attrs:{"src":require("../assets/services/s1/img2.png"),"alt":"img"}}),_c('img',{staticClass:"im8",attrs:{"src":require("../assets/services/s1/img2.png"),"alt":"img"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"head-col",attrs:{"id":"scrolled"}},[_c('p',{staticClass:"mbottom"},[_vm._v(" La transformación digital es inminente, ¿para qué seguir esperando? Con expertos en desarrollo web, hemos formado un gran equipo con las habilidades necesarias para hacer realidad cualquier tipo de plataforma digital y unirte a la nueva era. ")]),_c('p',[_vm._v(" Todos nuestros proyectos son diseñados desde una perspectiva UI (Interfaz de Usuario) y UX (Experiencia de Usuario) para hacerlos perfectamente funcionales en los objetivos de tu marca. ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-img-1 pc"},[_c('img',{staticClass:"devimg-1",attrs:{"src":require("../assets/dev1.jpg"),"alt":"img"}}),_c('img',{staticClass:"devimg-2",attrs:{"src":require("../assets/dev2.jpg"),"alt":"img"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"points only-mov"},[_c('p',{staticClass:"titulo"},[_vm._v("Tienda en línea")]),_c('p',{staticClass:"texto"},[_vm._v(" Prepárate para vender en línea tus productos y servicios. Nuestras tiendas en línea están habilitadas para recibir pagos y procesar envíos a todas partes del mundo. ")]),_c('a',{staticClass:"ver__mov",attrs:{"href":"/tienda-en-linea"}},[_vm._v("Ver más")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"points only-mov"},[_c('p',{staticClass:"titulo"},[_vm._v("Sitios web")]),_c('p',{staticClass:"texto"},[_vm._v(" No hay mejor presentación para darte a conocer en el mundo digital. Un sitio web te da notoriedad, confianza y un medio para que tus clientes te encuentren con facilidad. ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"points"},[_c('p',{staticClass:"titulo"},[_vm._v("Páginas de destino")]),_c('p',{staticClass:"texto"},[_vm._v(" Para mejorar los resultados y el control de tus campañas de Meta y Google Ads, necesitas una página de aterrizaje. Se trata de un desarrollo web sencillo, dirigido a generar una acción inmediata de tu cliente objetivo. Por ejemplo: Visitar tu página, realizar una suscripción o una compra en línea. ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"points"},[_c('p',{staticClass:"titulo"},[_vm._v("Software para empresas  ")]),_c('p',{staticClass:"texto"},[_vm._v(" Minimiza tiempos y recursos con un software a la medida de tu empresa. Desde un control de inventario hasta sistemas que midan la productividad de tus empleados. ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"points"},[_c('p',{staticClass:"titulo"},[_vm._v("Aplicaciones móviles")]),_c('p',{staticClass:"texto"},[_vm._v(" Reservar una consulta médica, comprar boletos de avión, pagar servicios o realizar compras en tu celular, todo esto posible a través de una aplicación móvil. ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"points"},[_c('p',{staticClass:"titulo"},[_vm._v("Sistema de citas")]),_c('p',{staticClass:"texto"},[_vm._v(" ¡Empieza a poner orden! Con un sistema de citas puedes llevar un mejor control de tu agenda y facilitas el camino de tu cliente a tus servicios. ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-img-2 pc"},[_c('img',{staticClass:"devimg-3",attrs:{"src":require("../assets/dev3.jpg"),"alt":"img"}}),_c('img',{staticClass:"devimg-4",attrs:{"src":require("../assets/dev4.jpg"),"alt":"img"}}),_c('img',{staticClass:"devimg-5",attrs:{"src":require("../assets/dev5.jpg"),"alt":"img"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-img-1 mo",attrs:{"data-aos":"fade-zoom-in","data-aos-easing":"ease-in-back","data-aos-duration":"500"}},[_c('img',{staticClass:"devimg-1",attrs:{"src":require("../assets/dev1.jpg"),"alt":"img"}}),_c('img',{staticClass:"devimg-2",attrs:{"src":require("../assets/dev2.jpg"),"alt":"img"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-img-2 mo",attrs:{"data-aos":"fade-zoom-in","data-aos-easing":"ease-in-back","data-aos-duration":"500"}},[_c('img',{staticClass:"devimg-3",attrs:{"src":require("../assets/dev3.jpg"),"alt":"img"}}),_c('img',{staticClass:"devimg-4",attrs:{"src":require("../assets/dev4.jpg"),"alt":"img"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"ddm-sec",attrs:{"data-aos":"fade-zoom-in","data-aos-easing":"ease-in-back","data-aos-duration":"500"}},[_c('p',{staticClass:"pc"},[_vm._v("¿No encuentras lo que necesitas?")]),_c('p',{staticClass:"pc"},[_vm._v(" No dudes en "),_c('a',{attrs:{"href":"/home/contact-us"}},[_vm._v("hablarnos.")])]),_c('p',{staticClass:"mo"},[_vm._v("¿No encuentras lo "),_c('br',{staticClass:"mo"}),_vm._v(" que necesitas? No dudes "),_c('br',{staticClass:"mo"}),_vm._v(" en "),_c('a',{attrs:{"href":"/home/contact-us"}},[_vm._v("hablarnos.")])])])
}]

export { render, staticRenderFns }